import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from '../../services/api/api.service';
import { ToastrService } from "ngx-toastr";
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  
  forgotForm: FormGroup;
  submitted: boolean;
  type = 'admin';
  constructor(
    private router: Router,
    private fb: FormBuilder, 
    private api: ApiService,
    private comm: CommonService,
    private route: ActivatedRoute) { 
        if(this.route.snapshot.params.userType) this.type = 'vendor'
    }

  ngOnInit() {
    this.forgotForm = this.fb.group({
      email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]]
    });
  }

  onSubmit() {
    this.submitted=true
    if(this.forgotForm.valid){
        this.api.forgotPassword(this.forgotForm.value, this.type).subscribe(response=>{
          if(response['status']==200){
            this.goToLogin();
            this.comm.successMsg(response['message']);
          }else{
            this.comm.errorMsg(response['message'])
          }
        })
      }else{
        this.forgotForm.markAllAsTouched();
      }
  }

  goToLogin(){
    if(this.type == 'admin'){
     this.router.navigate(['/'])
    } else{
     this.router.navigate(['/vender'])
    }
  }
}
