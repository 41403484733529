import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { UrlService } from '../url/url.service';

@Injectable({
  providedIn: 'root'
})
export class VendorApiService {

  constructor(
    private http: HttpClient,
    private url: UrlService
  ) { }

   /*************************************** Manage Services ******************************************/
   getServices(body){
    return this.http.post(`${this.url.serverUrl}/vendor/getServices`, body);
  }
   addServices(body){
    return this.http.post(this.url.addServices, body);  
   }

   updateServices(body){
    return this.http.put(this.url.updateServices, body);  
   }

   fetchServiceById(data){
    return this.http.post(this.url.fetchServiceById, data); 
   }

   getServicesList(){
    return this.http.post(`${this.url.serverUrl}/vendor/getServicesList`,'');  
  }

   ///////////////////////////////////  ** Manage Services End ** ///////////////////////////////////


  /************************************* Manage Professional **********************************************/
  getProfessionals(body){
    return this.http.post(this.url.getProfessionalsForVendor, body);
  }
  
  fetchProfessionalsById(data){
    return this.http.post(this.url.fetchProfessionalsById, data); 
  }

  addProfessionals(body){
    return this.http.post(this.url.addProfessionals, body);  
   }

  updateProfessionals(body){
    return this.http.put(this.url.updateProfessionals, body);  
  }

  freeProfessionalByService(body) {
    return this.http.post(`${this.url.serverUrl}/vendor/freeProfessionalByService`, body);  
  }
   ///////////////////////////////////  ** Manage Professional End ** ///////////////////////////////////

   /***********************************  Bookings Start  ***************************************/

   getBookings(body) {
    return this.http.post(`${this.url.serverUrl}/vendor/getBookings`, body);  
   }

   createBooking(body) {
    return this.http.post(`${this.url.serverUrl}/vendor/createBooking`, body);  
   }

   acceptNewBooking(body) {
    return this.http.post(`${this.url.serverUrl}/vendor/acceptNewBooking`, body);  
   }
   
   
  /////////////////////////////////////  Bookings End ////////////////////////////////////////////
  getVendorDashboard(body) {
    return this.http.post(`${this.url.serverUrl}/vendor/getDashboard`, body)
  }


   
   viewServiceProviderById(body){
    return this.http.post(`${this.url.serverUrl}/vendor/viewServiceProviderById`, body);
  } 

   updateOwnerDetails(body){
     return this.http.put(`${this.url.serverUrl}/vendor/updateOwnerDetails`, body);
   }  

   getBusinessDetailsById(body){
    return this.http.post(`${this.url.serverUrl}/vendor/getBusinessDetailsById`, body);
   }

   updateBusinessDetails(body){
      return this.http.post(`${this.url.serverUrl}/vendor/addBusinessDetails`, body);
    }
  
    getDocumentsById(body){
      return this.http.post(`${this.url.serverUrl}/vendor/getDocumentsById`, body);
    }

    addUpdateDocumentDetails(body){
      return this.http.post(`${this.url.serverUrl}/vendor/addDocuments`, body);
    }
  
    getGalleryItems(body){
      return this.http.post(`${this.url.serverUrl}/vendor/getGallaryItems`, body);
    }
  
    manageGallary(body){
      return this.http.post(`${this.url.serverUrl}/vendor/manageGallary`, body);
    }
  
    manageService(body){
      return this.http.post(`${this.url.serverUrl}/vendor/manageServices`, body);
    }

    addProfessional(body){
      return this.http.post( `${this.url.serverUrl}/vendor/manageProfessionals`, body);
    }

    getNotifications(body) {
      return this.http.post(`${this.url.serverUrl}/vendor/getNotification`, body);
    }
}
