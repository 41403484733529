import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class GetInterceptorService {

  constructor(private router: Router,
    private toastr: ToastrService,
    private common: CommonService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.common.hideSpinner();
        if (event.status == 211) {
          this.toastr.error('Your session is expired, please log in.');
          localStorage.clear();
          return this.router.navigateByUrl('/');
        }
      }
    }));
  }
}
