import { Component, OnInit, ViewChildren, QueryList, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ApiService } from '../../services/api/api.service';
import { ToastrService } from "ngx-toastr";


@Component({
  selector: 'app-add-credential',
  templateUrl: './add-credential.component.html',
  styleUrls: ['./add-credential.component.scss']
})
export class AddCredentialComponent implements OnInit {

  serviceList: any[];
  serviceCode: any[];
  credentialData: any;
  addCredential: FormGroup;
  fieldTextType: boolean;
  //inputList: FormArray
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  @ViewChildren('items') items: QueryList<ElementRef>;

  constructor(private fb: FormBuilder, private api: ApiService,
    public dialogRef: MatDialogRef<AddCredentialComponent>, private toastr: ToastrService, private renderer: Renderer2,
    private el: ElementRef) { }

  ngOnInit() {
    this.addCredential = this.fb.group({
      serviceType: ['', [Validators.required]],
      serviceCode: [''],
      inputList: this.fb.array([
        this.fb.control('')
      ]

      )
    })

    this.getServiceType();
  }

  get inputList() {

    return this.addCredential.get('inputList') as FormArray;
  }


  addInputList() {
    for (let i = 0; i <= this.credentialData.length; i++) {
      this.inputList.push(

        this.fb.control(''));


    }


  }



  getServiceType() {
    let data = {
      pageNo: 1
    }
    this.api.seriveType(data).subscribe(response => {
      if (response['statusCode'] == 200) {
        this.serviceList = response['data']['seriveTypeData'];
      }
    })
  }

  onServiceTypeChange(event) {
    this.serviceCode = [];
    if (event.value != '') {
      var item = this.serviceList.find(item => item.seriveType === event.value);
      this.serviceCode = item['seriveCodeData'];
      if (this.serviceCode && this.serviceCode.length > 0) {
        this.addCredential.controls['serviceCode'].setValue(this.serviceCode[0].seriveCode);
        this.onSelectServiceCode(this.serviceCode[0].seriveCode);

      }
    }
  }

  onSelectServiceCode(event) {
    var item = this.serviceCode.find(item => item.seriveCode === event);
    this.credentialData = item.credentialData;
    console.log(this.credentialData);


    // this.addFields();

  }
  addFields() {
    console.log("triggered")
    const textboxes = document.getElementById('dynamicDiv');
    for (let i = 0; i <= this.credentialData.length; i++) {
      var input = this.renderer.createElement('input');

      this.renderer.appendChild(textboxes, input);
    }

  }

  addCredentials() {
    //  console.log("called")
    // console.log(this.addCredential);
    // let temp = this.items.toArray();
    // // console.log(temp);
    // console.log(this.renderer)
    // for (let i = 0; i <= temp.length; i++) {

    //   //for (let j = 0; j <= temp[i].nativeElement.length;j++)
    //   console.log(temp[i]);
    // }
    console.log("submitted")

  }

}
