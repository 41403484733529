import { Component, OnInit, ViewChildren, QueryList, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ToastrService } from "ngx-toastr";
import { ApiService } from 'src/app/services/api/api.service';
import { CommonService } from 'src/app/services/common/common.service';


@Component({
  selector: 'app-add-edit-category',
  templateUrl: './add-edit-category.component.html',
  styleUrls: ['./add-edit-category.component.scss']
})
export class AddEditCategoryComponent implements OnInit {
  submitted: boolean = false;
  categoryDetails: any;
  addEditCategoryForm: FormGroup;
  categoryId: any;
  imageFile : any;
  userId : any;
  constructor(
    private fb: FormBuilder, 
    private _api: ApiService,
    private _comm : CommonService,
    public dialogRef: MatDialogRef<AddEditCategoryComponent>
    ) { 
      this.userId = JSON.parse(localStorage.getItem('super_admin')).id
      this.addEditCategoryForm= this.fb.group({
        photo : ['',Validators.required],
        category : ['',Validators.required],
      });
    }

  ngOnInit() {
    if(this.categoryId){
      let reqBody = {id : this.categoryId}
      this._api.getCategoryById(reqBody).subscribe( response => {
        this.categoryDetails = response['data'][0];
        this.addEditCategoryForm.controls['photo'].setValue(this.categoryDetails.photo);
        this.addEditCategoryForm.controls['category'].setValue(this.categoryDetails.category);
      })
    }
  }
  onCategoryImage(event){
    if (event.target.files && event.target.files[0]) {
      if (this._comm.validateImageFile(event.target.files[0])) {
      let data = new FormData();
      data.append('file',event.target.files[0])
      data.append('type','1')
      this._api.uploadFile(data).subscribe( (response : any) => {
      this.addEditCategoryForm.controls['photo'].setValue(response.data.linkS3);
      console.log(this.addEditCategoryForm.controls['photo'].value);
      })
    }
  }
  }

  onSubmit(){
    this.submitted = true;
    if(this.addEditCategoryForm.valid){
      const reqBody = this.addEditCategoryForm.value;
      if(!this.categoryId){
        this._api.addCategory(reqBody).subscribe( response => {
          if(response['status'] == 200){
            this._comm.successMsg(response['message'])
            this.dialogRef.close(true);
          }else{
            this._comm.errorMsg(response['message'])
          }
        }) 
      }else{
        reqBody['id'] = this.categoryId;
        this._api.editCategory(reqBody).subscribe( response => {
          if(response['status'] == 200){
            this._comm.successMsg(response['message'])
            this.dialogRef.close(true);
          }else{
            this._comm.errorMsg(response['message'])
          }
        }) 
      }
    }
  }

  closeModal(){
    this.dialogRef.close(false);
  }
}
