import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root'
})

export class UrlService {
  userType = '';
  // baseUrl = 'http://appgrowthcompany.com:9038/admin';
  // serverUrl ='http://appgrowthcompany.com:9038';

  baseUrl='https://demo.appdukaan.com:5013/admin';
  serverUrl='https://demo.appdukaan.com:5013'

  constructor(
    private _data: DataService
  ) { 
  }
  
  getUserType(){
    this._data.userType.subscribe( res => {
      if (res == 'admin') {
          this.userType = 'admin'
        }
      else if(res == 'vender'){
          this.userType = 'vendor'
        }  
    })
    return this.userType;
  }

  getUserTypeLocal(){
  
      if (localStorage.getItem('type') == 'admin') {
          this.userType = 'admin'
        }
      else if(localStorage.getItem('type') == 'vender'){
          this.userType = 'vendor'
        }  

    return this.userType;
  }

/************************** Accounts  ******************************************/
  logOut = `${this.baseUrl}/logout`;
  changePassword = `${this.baseUrl}/changePassword`;
  changeVendorPassword = `${this.serverUrl}/vendor/changePassword`;
  verifyUserEmail = `${this.serverUrl}/user/verifyUser`;
  uploadFile = `${this.serverUrl}/upload/uploadfile`;
/////////////////////////////////////////////////////////////////////////////////

/************************ Service Providers ************************************/
  vendorSignUp = `${this.serverUrl}/vendor/signUp`
  addServiceProvider = `${this.serverUrl}/${this.getUserType()}/addServiceProvider`;
  getServiceProviderSelectList = `${this.serverUrl}/admin/getAllServiceProvider`
  updateOwnerDetails = `${this.serverUrl}/${this.getUserType()}/updateOwnerDetails`;
  getBusinessDetailsById =`${this.serverUrl}/${this.getUserType()}/getBusinessDetailsById`;
  getBusinessDetailsByIdForAdin = `${this.serverUrl}/vendor/getBusinessDetailsById`;
  updateBusinessDetails = `${this.serverUrl}/${this.getUserType()}/addBusinessDetails`;
  getDocumentsById = `${this.serverUrl}/${this.getUserType()}/getDocumentsById`;
  getDocumentsByIdForAdmin = `${this.serverUrl}/vendor/getDocumentsById`;
  addDocuments = `${this.serverUrl}/${this.getUserType()}/addDocuments`;
  getGalleryItems = `${this.serverUrl}/${this.getUserType()}/getGallaryItems`;
  getGalleryItemsForAdmin = `${this.serverUrl}/vendor/getGallaryItems`;
  manageGallary = `${this.serverUrl}/${this.getUserType()}/manageGallary`;
  manageServices = `${this.serverUrl}/${this.getUserType()}/manageServices`;
  getServices = `${this.serverUrl}/${this.getUserType()}/getServices`;
  getServicesForAdmin = `${this.serverUrl}/vendor/getServices`;
  getProfessionals = `${this.serverUrl}/${this.getUserType()}/getProfessionals`;
  manageProfessionals = `${this.serverUrl}/${this.getUserType()}/manageProfessionals`;
////////////////////////////////////////////////////////////////////////////////

/****************************** Users ******************************************/

////////////////////////////////////////////////////////////////////////////////

/******************************* Categories ***********************************/
  addCategory = `${this.baseUrl}/createCategories`;
  getCategories = `${this.baseUrl}/getCategories`;
  editCategories = `${this.baseUrl}/editCategories`;
  viewCategoriesById = `${this.baseUrl}/viewCategoriesById`;
  getCategoriesList = `${this.serverUrl}/${this.getUserTypeLocal()}/getCategoriesList`
////////////////////////////////////////////////////////////////////////////////

/******************************* Inventory ***********************************/
  addInventory = `${this.serverUrl}/${this.getUserType()}/addInventory`;
  getInventory = `${this.serverUrl}/${this.getUserType()}/getInventory`;
  updateInventory = `${this.serverUrl}/${this.getUserType()}/updateInventory`;
  viewInventoryItemsById = `${this.serverUrl}/${this.getUserType()}/viewInventoryItemsById`;
  getProductForAdmin = `${this.serverUrl}/vendor/getInventory`;
////////////////////////////////////////////////////////////////////////////////


/******************************* Service ***********************************/
  addServices = `${this.serverUrl}/vendor/addServices`;
  updateServices = `${this.serverUrl}/vendor/updateServices`;
  fetchServiceById = `${this.serverUrl}/vendor/fetchServiceById`;
  getServicesForVendor = `${this.serverUrl}/vendor/getServices`;
////////////////////////////////////////////////////////////////////////////////

/******************************* Professional ***********************************/
  addProfessionals = `${this.serverUrl}/vendor/addProfessionals`;
  updateProfessionals = `${this.serverUrl}/vendor/updateProfessionals`;
  fetchProfessionalsById = `${this.serverUrl}/vendor/fetchProfessionalsById`;
  getProfessionalsForAdmin = `${this.serverUrl}/vendor/getProfessionals`;
  getProfessionalsForVendor = `${this.serverUrl}/vendor/getProfessionals`;
////////////////////////////////////////////////////////////////////////////////

/******************************* CMS ***********************************/
getCMS = `${this.baseUrl}/getCms`;
updateCMS = `${this.baseUrl}/addCms`;
////////////////////////////////////////////////////////////////////////////////

/******************************* Discount and Coupons *******************/
getDiscountAndCoupon = `${this.serverUrl}/admin/getDiscountAndCoupon`;
addDiscountAndCoupon = `${this.serverUrl}/admin/addDiscountAndCoupon`;
updateDiscountAndCoupon = `${this.serverUrl}/admin/updateDiscountAndCoupon`;
viewCouponById = `${this.serverUrl}/admin/viewCouponById`
//////////////////////////////////////////////////////////////////////////////



/////////////////////////  ***  others *** //////////////////////////////////////
  getMessageType = `${this.baseUrl}/getMessageType`;
  getDefaultMessages = `${this.baseUrl}/getDefaultMessages`;
  addDefaultMessages = `${this.baseUrl}/addDefaultMessages`;
  editDefaultMessages = `${this.baseUrl}/editDefaultMessages`;
  getTags = `${this.baseUrl}/getTags`;
  addAdmin = `${this.baseUrl}/addAdmin`;
  uploadFileWithS3 = `${this.baseUrl}/uploadFileWithS3`;
  getAllAdminProfile = `${this.baseUrl}/getAllAdminProfile`;
  deleteBlockUnBlockDeactivateAdminProfile = `${this.baseUrl}/deleteBlockUnBlockDeactivateAdminProfile`;
  getAdminCredentials = `${this.baseUrl}/getAdminCredentials`;
  seriveType= `${this.baseUrl}/seriveType`;
}
