import { Injectable } from '@angular/core';
import io from "socket.io-client";
import { UrlService } from '../url/url.service';
import { Observable } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class SocketService {
  socket;
  constructor(
    private _url : UrlService
  ) {
    this.socket = io(this._url.serverUrl)
    if(localStorage.getItem('super_admin')){
      this.socket.emit("onlineServiceProvider",{serviceProviderId:JSON.parse(localStorage.getItem('super_admin')).id})
    }
   }

   bookingListner() {
    return Observable.create(observer => {
      this.socket.removeListener('sendNotificationToServiceProvider');
      this.socket.on("sendNotificationToServiceProvider", message => {
        observer.next(message);
      });
    });
   }
}
