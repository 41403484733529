import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { ToastrModule } from 'ngx-toastr';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GetInterceptorService } from './services/get-interceptor/get-interceptor.service';
import { MaterialModule } from './material/material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SetInterceptorService } from './services/set-interceptor/set-interceptor.service';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AddMessageComponent } from './modals/add-message/add-message.component';
import { AddCredentialComponent } from './modals/add-credential/add-credential.component';
import { VendorloginComponent } from './auth/vendorlogin/vendorlogin.component';
import { SignupvendorComponent } from './auth/signupvendor/signupvendor.component';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.module';
import { AdmintrackingComponent } from './auth/admintracking/admintracking.component';
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { AddEditCategoryComponent } from './pages/managecategories/add-edit-category-modal/add-edit-category.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

//********** Calender *//
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { FlatpickrModule } from 'angularx-flatpickr';
import { AddEditBookingsModalComponent } from './pages/bookingadmin/modals/add-edit-bookings-modal/add-edit-bookings-modal.component';
import { AddEditServicesComponent } from './pages/manage-services/add-edit-services/add-edit-services.component';
import { AddEditProfessionalComponent } from './pages/manage-professional/add-edit-professional/add-edit-professional.component';
import { ToastrModule } from 'ng6-toastr-notifications';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { StaticPagesAppComponent } from './static-pages-app/static-pages-app.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { SocketService } from './services/socket/socket.service'
import { NgxPaginationModule } from 'ngx-pagination';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AboutComponent } from './about/about.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    AddMessageComponent,
    AddCredentialComponent,
    VendorloginComponent,
    SignupvendorComponent,
    AdmintrackingComponent,
    AddEditCategoryComponent,
    AddEditBookingsModalComponent,
    AddEditServicesComponent,
    AddEditProfessionalComponent,
    StaticPagesAppComponent,
    AboutComponent
  

  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCemWI5lmshgQQxrak6y_V4z6XcTWDzArE",
      libraries: ["geometry", "places", "drawing"]
    }),
    AgmDirectionModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,

    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    DragAndDropModule,
    GooglePlaceModule,
    NgMultiSelectDropDownModule.forRoot(),
    AngularMultiSelectModule,
    SelectDropDownModule,
    NgxPaginationModule
  ],

  entryComponents: [
    AddMessageComponent,
    AddCredentialComponent,
    AddEditCategoryComponent,
    AddEditBookingsModalComponent,
    AddEditServicesComponent,
    AddEditProfessionalComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA , NO_ERRORS_SCHEMA
  ],
  
  providers: [
    NgxSpinnerService,
    SocketService,
    { provide: HTTP_INTERCEPTORS, useClass: SetInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GetInterceptorService, multi: true },
    {provide: ToastrService, useValue: ToastrService}
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
