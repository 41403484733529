import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from '../../services/api/api.service';
import { MustMatch } from './must-match.validator';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  
  resetPasswordForm: FormGroup;
  resetToken: any;
  expired : boolean = false;
  type = '';

  constructor(private activatedRoute: ActivatedRoute, 
    private router: Router,
    private fb: FormBuilder, 
    private api: ApiService,
    private comm: CommonService) { 
     this.resetToken = this.activatedRoute.snapshot.params.id
     if(this.resetToken){
       this.comm.showSpinner();
       if(window.location.href.includes("/vender")){
        this.type = 'vendor'
       }else if(window.location.href.includes("/user")){
        this.type = 'user'
       }else{
        this.type = 'admin'
       }

      this.api.checkForgotPassword(this.resetToken,this.type).subscribe(response => {
        if(response['status']!==200){
          this.comm.errorMsg(response['message']);
          this.expired = true;
        //  this.router.navigate(['/forgotPassword']) 
        }
      })
      }
  }

  ngOnInit() {
    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]
    }, {
        validator: MustMatch('password', 'confirmPassword')
    });
  }

  onSubmit() {
    if(this.resetPasswordForm.valid){
      const data={
        password:this.resetPasswordForm.controls['password'].value,
        link:this.resetToken
      }
      this.api.resetPassword(data,this.type).subscribe(response=>{
        debugger
        if(response['status']==200){
          this.comm.successMsg(response['message']);
          if(this.type == 'admin'){
            this.router.navigate(['/'])
          }else if(this.type == 'vendor'){
            this.router.navigate(['/vender'])
          }else if(this.type == 'user'){
            this.router.navigate(['thankyou','app'])
          }
        }else{
          this.comm.errorMsg(response['message']);
        }
      })
    }else{
      this.resetPasswordForm.markAllAsTouched();
    }
   
  }
}
