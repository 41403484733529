import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { UrlService } from '../url/url.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  userType: string = '';
  constructor(
    private http: HttpClient,
    private url: UrlService
  ) {}

  /************************************ Accounts *************************************/
  logIn(body,userType) {
    return this.http.post(`${this.url.serverUrl}/${userType}/login`, body);
  }

  forgotPassword(body, userType) {
    return this.http.post(`${this.url.serverUrl}/${userType}/forgotPassword`, body);
  }

  logOut(body) {
    return this.http.post(this.url.logOut, body);
  }

  checkForgotPassword(id, type) {
    return this.http.get(`${this.url.serverUrl}/${type}/checkForgotPassword/${id}`)
  }

  resetPassword(body, type) {
    return this.http.post(`${this.url.serverUrl}/${type}/resetPassword`, body);
  }

  changePassword(body){
    return this.http.post(this.url.changePassword, body);
  }

  changeVendorPassword(body){
    return this.http.post(this.url.changeVendorPassword, body);
  }

  verifyUserEmail(id){
    return this.http.get(`${this.url.verifyUserEmail}/${id}`);
  }

  uploadFile(body){
    return this.http.post(this.url.uploadFile, body);  
  }
  /////////////////////////////////// ** Accounts End ** /////////////////////////////////


  /********************************* Service Providers *********************************/
  vendorSignUp(body){
    return this.http.post(this.url.vendorSignUp, body);
  }

  addServiceProvider(body) {
    return this.http.post(this.url.addServiceProvider, body);
  }

  updateOwnerDetails(body){
    return this.http.put(this.url.updateOwnerDetails, body);
  } 

  getBusinessDetailsById(body){
    return this.http.post(this.url.getBusinessDetailsById, body);
  }

  getBusinessDetailsByIdForAdmin(body){
    return this.http.post(this.url.getBusinessDetailsByIdForAdin, body);
  }

  updateBusinessDetails(body){
    return this.http.post(this.url.updateBusinessDetails, body);
  }

  getDocumentsById(body){
    return this.http.post(this.url.getDocumentsById, body);
  }

  getDocumentsByIdForAdmin(body){
    return this.http.post(this.url.getDocumentsByIdForAdmin, body);
  }

  addUpdateDocumentDetails(body){
    return this.http.post(this.url.addDocuments, body);
  }

  getGalleryItems(body){
    return this.http.post(this.url.getGalleryItems, body);
  }

  getGalleryItemsForAdmin(body){
    return this.http.post(this.url.getGalleryItemsForAdmin, body);
  }

  manageGallary(body){
    return this.http.post(this.url.manageGallary, body);
  }

  addService(body){
    return this.http.post(this.url.manageServices, body);
  }

  getServices(body){
    return this.http.post(this.url.getServices, body);
  }

  getServicesForAdmin(body){
    return this.http.post(this.url.getServicesForAdmin, body);
  }

  getProfessionals(body){
    return this.http.post(this.url.getProfessionals, body);
  }

  addProfessional(body){
    return this.http.post(this.url.manageProfessionals, body);
  }


  /////////////////////////////// ** Service Providers End ** /////////////////////////////





  /************************************* Categories ******************************************/
  getCategories(body){
    return this.http.post(this.url.getCategories, body);  
  }

  addCategory(body){
    return this.http.post(this.url.addCategory, body);  
  }

  editCategory(body){
    return this.http.put(this.url.editCategories, body);  
  }

  getCategoryById(body){
    return this.http.post(this.url.viewCategoriesById, body);  
  }

  getCategoryList(){
    return this.http.post(this.url.getCategoriesList, '');  
  }

  ///////////////////////////////////  ** Categories End ** ///////////////////////////////////


    /************************************* Inventory ******************************************/
    getInventory(body){
      return this.http.post(this.url.getInventory, body);  
    }
  
    addInventory(body){
      return this.http.post(this.url.addInventory, body);  
    }
  
    updateInventry(body){
      return this.http.put(this.url.updateInventory, body);  
    }

    getInventoryItemsById(body){
      return this.http.post(this.url.viewInventoryItemsById, body);  
    }

    getProductsForAdmin(body){
      return this.http.post(this.url.getProductForAdmin, body);
    }
    ///////////////////////////////////  ** Inventory End ** ///////////////////////////////////

    getProfessionalsForAdmin(body){
      return this.http.post(this.url.getProfessionalsForAdmin, body);
    }

     /************************************* Manage CMS Start**********************************************/
  getCMS(){
      return this.http.get(this.url.getCMS); 
    }  

  UpdateCMS(data){
    return this.http.post(this.url.updateCMS, data); 
  }

   ///////////////////////////////////  ** Manage CMS End ** ///////////////////////////////////




  
   //********************************** Others ***********************************************//
  getMessageType(body) {
    return this.http.post(this.url.getMessageType, body);
  }

  getDefaultMessages(body) {
    return this.http.post(this.url.getDefaultMessages, body);
  }

  editDefaultMessages(body) {
    return this.http.post(this.url.editDefaultMessages, body);
  }

  addDefaultMessages(body) {
    return this.http.post(this.url.addDefaultMessages, body);
  }

  getTags(body) {
    return this.http.post(this.url.getTags, body);
  }

  addAdmin(body) {
    return this.http.post(this.url.addAdmin, body);
  }

  uploadFileWithS3(body) {
    return this.http.post(this.url.uploadFileWithS3, body);
  }

  getAllAdminProfile(body) {
    return this.http.post(this.url.getAllAdminProfile, body);
  }

  deleteBlockUnBlockDeactivateAdminProfile(body) {
    return this.http.post(this.url.deleteBlockUnBlockDeactivateAdminProfile, body);
  }

  getAdminCredentials(body) {
    return this.http.post(this.url.getAdminCredentials, body);
  }

  seriveType(body) {
    return this.http.post(this.url.seriveType, body);
  }
}
