import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data/data.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-vendorlogin',
  templateUrl: './vendorlogin.component.html',
  styleUrls: ['./vendorlogin.component.scss']
})
export class VendorloginComponent implements OnInit {
  vendorLogin: FormGroup
  submitted: boolean = false;
  remember: any;
  constructor(
    private router: Router, 
    private fb: FormBuilder, 
    private _api: ApiService,
    private route : ActivatedRoute, 
    private _comm: CommonService,
    private _data: DataService) {
    
  }

  ngOnInit() {
    if (sessionStorage.getItem("rememberVendor")) {
      let data = JSON.parse(sessionStorage.getItem("rememberVendor"));
      this.remember = true;
      this.vendorLogin = this.fb.group({
        email: [data.email, [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]],
        password: [data.password, [Validators.required]]
      });
    } else {
      this.vendorLogin = this.fb.group({
        email: ["rajatsuman@apptunix.com", [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]],
        password: ["Test@123", [Validators.required]]
      });
    }
  }
  goTosignupvendor() {
    this.router.navigate(['signupvendor'])
  }
  goToforgotPassword() {
    this.router.navigate(['forgotPassword','vendor'])
  }


  submit() {
    this.submitted = true
    if (this.vendorLogin.valid) {
      if (this.remember) {
        sessionStorage.setItem(
          "rememberVendor",
          JSON.stringify(this.vendorLogin.value)
        );
      } else {
        sessionStorage.clear();
      }
      this._api.logIn(this.vendorLogin.value,'vendor').subscribe(response => {
        if (response['status'] == 200) {
          localStorage.setItem('type', 'vender');
          this._data.updatedUserType('vender');
          localStorage.setItem('super_admin', JSON.stringify(response['data']));
          localStorage.setItem('onboardingStatus', JSON.stringify(response['data']['onboardingStatus']));
          location.reload();
          this._comm.successMsg("login successfully");
        } else {
          this._comm.errorMsg(response['message'])
        }
      });
    } else {
      this.vendorLogin.markAllAsTouched();
    }
  }

  signup() {
    localStorage.setItem('type', 'vender');
  }
}
