import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ApiService } from '../../services/api/api.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-add-message',
  templateUrl: './add-message.component.html',
  styleUrls: ['./add-message.component.scss']
})
export class AddMessageComponent implements OnInit {

  addMessageForm: FormGroup;
  index=0;
  tagList: any[];
  messageType: any[];
  data: any;
  languages: any[] = [
    {value: 'en', view : 'English'}
  ];

  constructor(private fb: FormBuilder,private api: ApiService,
					 public dialogRef: MatDialogRef<AddMessageComponent>,private toastr: ToastrService) { }

  ngOnInit() {
    this.addMessageForm = this.fb.group({
			message: ['',[Validators.required]],
      messagePrview: [''],
			subject: ['',[Validators.required]],
			lang: ['',[Validators.required]],
			isSms : [false],
      isEmail: [false],
			isPush: [false],
			messageType : ['',[Validators.required]],
      tag: ['']
		})
    this.addMessageForm.controls['lang'].setValue(this.languages[0]['value'])
    this.getTagList();
    this.getMessageTypeList();
  }

  getMessageTypeList(){
    let data = {
      pageNo: 1 
    }
    this.api.getMessageType(data).subscribe(response=>{
      if(response['statusCode']==200){
        this.messageType = response['data']['messageTypeData'];
        if(this.messageType && this.messageType.length>0){
          this.addMessageForm.controls['messageType'].setValue(this.messageType[0]['messageType'])
          if(this.data){
            this.addMessageForm.patchValue({
              message: this.data.message,
              subject: this.data.subject,
              lang: this.data.lang,
              isSms: this.data.isSms,
              isEmail: this.data.isEmail,
              isPush: this.data.isPush,
              messageType: this.data.messageType
            })
            this.messageTextChange(this.data.message);
          }
        }
      }
    })
  }

  getTagList(){
    let data = {
      pageNo: 1 
    }
    this.api.getTags(data).subscribe(response=>{
      if(response['statusCode']==200){
        this.tagList = response['data']['tagData'];
      }
    })
  }

  onMessageTagChange(event){
    let previewTag;
    for(let list of this.tagList){
      if(list.tagDisplay == event.value){
        previewTag = list.tagDisplayName;
      }
    }
    let data1 = this.addMessageForm.controls['message'].value;
    let dataPrview1 = this.addMessageForm.controls['messagePrview'].value;
    if(event.value != ''){
      if(data1.length == 0 && this.index == 0){
        let data2 =  data1.substr(0, this.index) + event.value + data1.substr(this.index);
        this.addMessageForm.controls['message'].setValue(data2);
        let dataPrview =  dataPrview1.substr(0, this.index) + previewTag + data1.substr(this.index);
        this.addMessageForm.controls['messagePrview'].setValue(dataPrview);
      }else if(data1.length>0 && this.index == 0){
        let data2 =  data1.substr(0, data1.length) + event.value + data1.substr(data1.length);
        this.addMessageForm.controls['message'].setValue(data2);
        let dataPrview =  dataPrview1.substr(0, data1.length) + previewTag + data1.substr(data1.length);
        this.addMessageForm.controls['messagePrview'].setValue(dataPrview);
      }else{
        let data2 = data1.substr(0, this.index) + event.value + data1.substr(this.index);
        this.addMessageForm.controls['message'].setValue(data2);
        let dataPrview =  dataPrview1.substr(0, this.index) + previewTag + data1.substr(this.index);
        this.addMessageForm.controls['messagePrview'].setValue(dataPrview);
      }
    }
    this.addMessageForm.controls['tag'].setValue('');
  }

  getCaretPos(onField) {
    if (onField.selectionStart || onField.selectionStart == '0') {
     this.index = (onField.selectionStart);
    }
  }

  messageTextChange(event){
    const tagObject = {};
    if(this.tagList && this.tagList.length>0){
      this.tagList.map((tag) => {
        tagObject[tag.tagDisplay.replace(/\[|\]/g, "")] = tag.tagDisplayName;
      });
      const regexKeys = new RegExp(Object.keys(tagObject).join('|'), 'g');
      let msg = event;
      if(msg.match(regexKeys) != null){
        msg.match(regexKeys).forEach((key) => {
          if(tagObject[key]){
          msg = msg.replace("[" + key + "]", tagObject[key]);
          }
        })
      }
      this.addMessageForm.controls['messagePrview'].setValue(msg);
    }
  }

  onAddMessage(){
    if(this.addMessageForm.valid){
      var data = {
        message: this.addMessageForm.value.message,
        subject: this.addMessageForm.value.subject,
        lang:this.addMessageForm.value.lang,
        isSms: this.addMessageForm.value.isSms,
        isEmail: this.addMessageForm.value.isEmail,
        isPush: this.addMessageForm.value.isPush,
        messageType:this.addMessageForm.value.messageType
      }
      this.api.addDefaultMessages(data).subscribe(response=>{
        if(response['statusCode']==200){
         this.toastr.success(response['message'])
         this.dialogRef.close("add");
        }else{
          this.toastr.error(response['message'])
          this.dialogRef.close();
        }
      })
    }else{
      this.addMessageForm.markAllAsTouched();
    }
  }

  onEditMessage(){
    if(this.addMessageForm.valid){
      var data = {
        message: this.addMessageForm.value.message,
        subject: this.addMessageForm.value.subject,
        lang:this.addMessageForm.value.lang,
        isSms: this.addMessageForm.value.isSms,
        isEmail: this.addMessageForm.value.isEmail,
        isPush: this.addMessageForm.value.isPush,
        messageType:this.addMessageForm.value.messageType,
        defulatMessageId: this.data._id
      }
      this.api.editDefaultMessages(data).subscribe(response=>{
        if(response['statusCode']==200){
         this.toastr.success(response['message'])
         this.dialogRef.close("edit");
        }else{
          this.toastr.error(response['message'])
          this.dialogRef.close();
        }
      })
    }
  }
}
