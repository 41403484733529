import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from '../../services/api/api.service';
import { ToastrService } from "ngx-toastr";
import { CommonService } from 'src/app/services/common/common.service';
import { DataService } from 'src/app/services/data/data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  remember: boolean = false;
  submitted: boolean;

  constructor(
    private router: Router, 
    private fb: FormBuilder, 
    private _api: ApiService, 
    private _comm: CommonService,
    private _data: DataService,) {
    if (sessionStorage.getItem("remember")) {
      let data = JSON.parse(sessionStorage.getItem("remember"));
      this.remember = true;
      this.loginForm = this.fb.group({
        email: [data.email, [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]],
        password: [data.password, [Validators.required, Validators.minLength(8)]]
      });
    } else {
      this.loginForm = this.fb.group({
        email: [environment.credentials.email, [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]],
        password: [environment.credentials.password, [Validators.required, Validators.minLength(8)]]
      });
    }
  }

  ngOnInit() {
  }

  goToforgotPassword() {
    this.router.navigate(['forgotPassword'])
  }

  onSubmit() {
    this.submitted = true
    if (this.loginForm.valid) {
      if (this.remember) {
        sessionStorage.setItem(
          "remember",
          JSON.stringify(this.loginForm.value)
        );
      } else {
        sessionStorage.clear();
      }
      this._api.logIn(this.loginForm.value,'admin').subscribe(response => {
        if (response['status'] == 200) {
          localStorage.setItem('super_admin', JSON.stringify(response['data']));
          localStorage.setItem('type', 'admin');
          this.router.navigate(['dashboard/home'])
          this._comm.successMsg("login successfully");
        } else {
          this._comm.errorMsg(response['message'])
        }
      });
    } else {
      this.loginForm.markAllAsTouched();
    }
  }
}
