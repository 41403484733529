import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './guards/auth.guard';

import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { VendorloginComponent } from './auth/vendorlogin/vendorlogin.component';
import { SignupvendorComponent } from './auth/signupvendor/signupvendor.component';
import { AdmintrackingComponent } from './auth/admintracking/admintracking.component';
import { AboutComponent } from './about/about.component';



const routes: Routes = [
 
  {
    path: 'vender',
    pathMatch: 'full',
    component: VendorloginComponent
  },
  {
    path: '',
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'forgotPassword/:userType',
    component: ForgotPasswordComponent
  },
  {
    path: 'changePassword',
    component: ChangePasswordComponent
  },
  {
    path: 'reset-password/:id',
    component: ResetPasswordComponent
  },
  {
    path: 'vender/reset-password/:id',
    component: ResetPasswordComponent
  },
  {
    path: 'user/reset-password/:id',
    component: ResetPasswordComponent
  },
  {
    path: 'signupvendor',
    component: SignupvendorComponent
  },
 
  
  {
    path: 'thankyou',
    component: AdmintrackingComponent
  },
  {
    path: 'thankyou/:id',
    component: AdmintrackingComponent
  },
  {
    path:'about',
    component:AboutComponent
  },
  {
    path: 'static-pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./static-pages-app/static-pages-app.module').then(m => m.StaticPagesAppModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },

 
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: "enabled",useHash : true})],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
