import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common/common.service';
declare var google: any;
import { MapsAPILoader } from "@agm/core";
@Component({
  selector: 'app-signupvendor',
  templateUrl: './signupvendor.component.html',
  styleUrls: ['./signupvendor.component.scss']
})
export class SignupvendorComponent implements OnInit {
  step: number = 0
  geoCoder: any;
  submitted:boolean = false;
  genderList: string[] = ['Men', 'Women', 'Both'];
  serviceProviderForm : FormGroup;
  singleDropDownSetting: any;
  next(i: number) {
    this.serviceProviderForm.markAllAsTouched();
    if(i == 1){
      if(this.checkOwnerDetail()){
        this.step = i;
        this.submitted = false
      } 
    }else{
      this.step = i 
    }
  }
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private _api: ApiService,
    private route: ActivatedRoute,
    private _comm: CommonService,
    private mapsAPILoader: MapsAPILoader) {
      this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder();
      });
      this.singleDropDownSetting = this._comm.singleDropSetting;
      this.serviceProviderForm = this.fb.group({
        ownerName: ['', [Validators.required]],
        ownerPhone: ['', [Validators.required,Validators.pattern("^[0-9]{6,15}$")]],
        ownerAddress: ['', [Validators.required]],
        ownerEmail: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]],
        countryCode: [''],
        businessName: ['', [Validators.required]],
        businessPhone: ['', [Validators.required, Validators.pattern("^[0-9]{6,15}$")]],
        businessAddress: ['', [Validators.required]],
        businessEmail: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")]],
        latitude: ['',Validators.required],
        longitude: ['',Validators.required],
        description: ['',Validators.required],
        salonFor: ['',Validators.required]
       
      });
     }

  ngOnInit() {
  }

  goTovender() {
    this.router.navigate(['vender'])
  }

  onVendorSignUp() {
    this.submitted = true;
    this.serviceProviderForm.markAllAsTouched();
    if(this.serviceProviderForm.valid){
      const data = this.serviceProviderForm.value;
      data.salonFor = data['salonFor'][0]
      this._api.vendorSignUp(data).subscribe( response => {
       if(response['status'] == 200){
         this._comm.successMsg(response['message'])
         this.goTovender();
       }else{
         this._comm.errorMsg(response['message'])
       }
      })
    }
  }

  checkOwnerDetail(){
    if(this.serviceProviderForm.controls['ownerName'].valid && 
    this.serviceProviderForm.controls['ownerPhone'].valid &&
    this.serviceProviderForm.controls['ownerAddress'].valid &&
    this.serviceProviderForm.controls['ownerEmail'].valid 
    ){
      return true;
    }else{
      this.submitted = true;
      return false
    }
  }

  handleAddress(address,type) {
    if (address.formatted_address) {
      if(type == 1){
        this.serviceProviderForm.controls['ownerAddress'].setValue(
          address.formatted_address
        );
      } else {
        this.serviceProviderForm.controls['businessAddress'].setValue(
          address.formatted_address
        );
        this.serviceProviderForm.controls['latitude'].setValue(
          address.geometry.location.lat()
        );
        this.serviceProviderForm.controls['longitude'].setValue(
          address.geometry.location.lng()
        );
      }
  
    }
  }
}
