import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-admintracking',
  templateUrl: './admintracking.component.html',
  styleUrls: ['./admintracking.component.scss']
})
export class AdmintrackingComponent implements OnInit {
  hideGoToHome: boolean = false;
  id ='';
  constructor(
    private route : ActivatedRoute,
    private _api : ApiService,
    private _comm : CommonService
  ) { 
    this.id = this.route.snapshot.params.id;
    if(this.id == 'app') {
      this.hideGoToHome = true;
    }else if(this.id !== '' && this.id !== undefined){
      this.hideGoToHome = true;
      this._api.verifyUserEmail(this.id).subscribe(response => {
        if(response['status'] == 200){
        this._comm.successMsg(response['message']);
        }else{
          this._comm.successMsg(response['message']);
        }
      })
    }
   }

  ngOnInit() {
  }

}
