import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { MatDialogRef } from '@angular/material';
import { VendorApiService } from 'src/app/services/api/vendor-api.service';

@Component({
  selector: 'app-add-edit-professional',
  templateUrl: './add-edit-professional.component.html',
  styleUrls: ['./add-edit-professional.component.scss']
})
export class AddEditProfessionalComponent implements OnInit {
  professionalId: any;
  caregoryListConfig: any;
  singleDropDownSetting: any;
  professionalForm: FormGroup;
  submitted: boolean = false;
  vendorDetail: any;
  professionalDetail: any;
  categoryList: any;
  genderList: string[] = ['Male', 'Female', 'Other'];

  constructor(
    private fb : FormBuilder,
    private _comm : CommonService,
    private router : Router,
    private _api : ApiService,
    private _vendorApi: VendorApiService,
    public dialogRef: MatDialogRef<AddEditProfessionalComponent>
    
  ) { 
    this.caregoryListConfig = this._comm.caregoryListConfig;
    this.singleDropDownSetting = this._comm.singleDropSetting;
    this.professionalForm = this.fb.group({
      professionalName: ['', [Validators.required]],
      category: ['', [Validators.required]],
      gender: ['',  [Validators.required]],
    });
    this.getCategoryList();
  }

  ngOnInit() {
    this.vendorDetail = JSON.parse(localStorage.getItem('super_admin'));
    
  }

  getCategoryList() {
    this._api.getCategoryList().subscribe( response =>{
      if (response['status'] == 200) {
        this.categoryList = response['data'];
        if(this.professionalId) this.fetchProfessionalsById(this.professionalId);
      } else {
        this._comm.errorMsg(response['message'])
      }
    })
  }

  fetchProfessionalsById(id){
    const data = {
      'id' : id
    }
      this._vendorApi.fetchProfessionalsById(data).subscribe( response =>{
        if (response['status'] == 200) {
          this.professionalDetail = response['data'][0]
         this.professionalForm.patchValue(response['data'][0])
         this.professionalForm.controls['gender'].setValue([response['data'][0]['gender']])
        } else {
          this._comm.errorMsg(response['message'])
        }
      }) 
  }

  addEditProfessional(){
    this.submitted = true;
    this.professionalForm.markAllAsTouched();
    if(this.professionalForm.valid){
      const data = this.professionalForm.value
      if(!this.professionalId){
       // data.gender = data.gender[0]
        this._vendorApi.addProfessionals(data).subscribe(response => {
          if (response['status'] == 200) {
            this._comm.successMsg(response['message'])
            this.dialogRef.close(true);
            this.router.navigate(['/dashboard/vender/professional'])
          } else {
            this._comm.errorMsg(response['message'])
          }
        })
      } else{
        data.id = this.professionalId;
       // if(this.professionalDetail !== data.gender ) data.gender = data.gender[0];
        this._vendorApi.updateProfessionals(data).subscribe(response => {
          if (response['status'] == 200) {
            this._comm.successMsg(response['message'])
            this.dialogRef.close(true);
            this.router.navigate(['/dashboard/vender/professional'])
          } else {
            this._comm.errorMsg(response['message'])
          }
        })
      }
  
    }
  }
  closeModal(){
    this.dialogRef.close(false);
  }
}
