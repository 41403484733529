import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private type = new BehaviorSubject<string>('');
  userType = this.type.asObservable();

  constructor() { }

  updatedUserType(data){
    this.type.next(data);
  }
  
}