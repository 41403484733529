import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})

export class SetInterceptorService {

  constructor(
    private common:CommonService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.common.showSpinner();
    const clonedReq = this.handleRequest(req);
    return next.handle(clonedReq);
  }
  handleRequest(req: HttpRequest<any>) {
    const user = JSON.parse(localStorage.getItem('super_admin'));
    let authReq;

    if ((req.method.toLowerCase() == 'post' || req.method.toLowerCase() == 'put') && req.body instanceof FormData) {
      authReq = req.clone({
        headers: new HttpHeaders({
          'Authorization': user ? user.authToken : ''
        })
      });
    }
    else {
      authReq = req.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': user ? user.authToken : ''
        })
      });
    }
    return authReq;
  }
}
