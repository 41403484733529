import { Component } from '@angular/core';
import { DataService } from './services/data/data.service';
import { Router } from '@angular/router';
import { SocketService } from './services/socket/socket.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'apptunixProduct';
  userType ='';
  constructor(
    private _data: DataService,
    private router: Router,
    private _socket: SocketService
  ){

    if(localStorage.getItem('type')) {
      this.userType = localStorage.getItem('type');
      this._data.updatedUserType(this.userType);
      if(this.userType == 'vender')
      if(JSON.parse(localStorage.getItem('onboardingStatus')) < 5){
        this.router.navigate(['/dashboard/vender/vendor-profile'])
      }else{
        this.router.navigate(['/dashboard/vender/home'])
      }
    }
  }
 
}
