import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api/api.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  cmsaboutus: any

  constructor(private _api: ApiService,) {
    this._api.getCMS().subscribe((response: any) => {

      console.log("response", response)
      this.cmsaboutus = response.data[0].aboutUs

    }, (err: any) => {
      console.log(err)

    });
  }

  ngOnInit() {
  }

}
