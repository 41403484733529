import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { VendorApiService } from 'src/app/services/api/vendor-api.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-add-edit-bookings-modal',
  templateUrl: './add-edit-bookings-modal.component.html',
  styleUrls: ['./add-edit-bookings-modal.component.scss']
})
export class AddEditBookingsModalComponent implements OnInit {
  bookingId:any;
  serviceProviderDetails : any;
  serviceListConfig: any;
  serviceList: any;
  genderList: string[] = ['Male', 'Female', 'Others']
  professionalList: any;
  professionalListConfig: any;
  bookingForm: FormGroup;
  submitted: boolean = false;
  constructor(
    private route : ActivatedRoute,
    private _api : ApiService,
    private _vendorApi: VendorApiService,
    private _comm : CommonService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddEditBookingsModalComponent>) {
      this.serviceListConfig = this._comm.serviceListConfig;
      this.professionalListConfig = this._comm.professionalListConfig;
      this.getServiceList();
      
     }
  ngOnInit() {
    this.createForm();
  }

  getServiceList() {
    this._vendorApi.getServicesList().subscribe( response => {
    this.serviceList = response['data'];
    })
  }
  createForm() {
    this.bookingForm = this.fb.group({
      userName: ['', [Validators.required]],
      userPhone: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      services: ['', [Validators.required]],
      bookingDate: ['', [Validators.required]],
      bookingType: [1, [Validators.required]],
      professionalId:['',]
    });
  }

  getprofessionalList() {
    const data = this.bookingForm.value;
    this._vendorApi.freeProfessionalByService({serviceProviderId:data.services.id}).subscribe( response => {
      if(response['status'] == 200){
        this.professionalList = response['data'];
      } else {
        this._comm.errorMsg(response['message']);
      }
    })
  }

  createBooking() {
    const data = this.bookingForm.value;
    data['professionalId'] = data.professionalId._id
    console.log("booking request", data);
    this._vendorApi.createBooking(data).subscribe( response => {
      if(response['status'] == 200){
        this._comm.successMsg(response['message']);
        this.dialogRef.close(true);
      } else {
        this._comm.errorMsg(response['message']);
      }
    })
  }

  onSaveOrSearchProfessional() {
    this.submitted = true;
    if(this.bookingForm.valid) {
      if(!this.professionalList || this.professionalList.length == 0) {
        this.getprofessionalList();
      } else {
        this.createBooking();
      }
    }
  }

  closeModal(){
    this.dialogRef.close(false);
  }
}
