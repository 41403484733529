import { Component, OnInit } from '@angular/core';
import { VendorApiService } from 'src/app/services/api/vendor-api.service';
import { CommonService } from 'src/app/services/common/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-add-edit-services',
  templateUrl: './add-edit-services.component.html',
  styleUrls: ['./add-edit-services.component.scss']
})
export class AddEditServicesComponent implements OnInit {
  serviceId:any;
  submitted: boolean = false;
  categoryList : any;
  servicesDetailForm: FormGroup;
  dropDownSetting : any;
  singleDropDownSetting : any;
  vendorDetail: any;
  config: any;
  constructor(
    private _vendorApi : VendorApiService,
    private _api : ApiService,
    private _comm : CommonService,
    private fb : FormBuilder,
    public dialogRef: MatDialogRef<AddEditServicesComponent>
  ) { 
    this.servicesDetailForm = this.fb.group({
      serviceName: ['', [Validators.required]],
      category: ['', [Validators.required]],
      price: ['', [Validators.required]],
      duration: ['', [Validators.required]],
      photo:[''],
    });
    this.getCategoryList();
    this.vendorDetail = JSON.parse(localStorage.getItem('super_admin'));
  }

  ngOnInit() {
    this.dropDownSetting = this._comm.dropSetting;
    this.singleDropDownSetting = this._comm.singleDropSetting;
    this.config = this._comm.caregoryListConfig;
  }

  getServiceById(){
    this._vendorApi.fetchServiceById({id:this.serviceId}).subscribe(response => {
      if (response['status'] == 200) {
   
        this.servicesDetailForm.patchValue(response['data'][0]);
        setTimeout(() => {
          this.dropDownSetting = this._comm.dropSetting;
          this.singleDropDownSetting = this._comm.singleDropSetting;
        }, 0);
 
      } else {
        this._comm.errorMsg(response['message'])
      }
    })
  }

  getCategoryList() {
    this._api.getCategoryList().subscribe( response =>{
      if (response['status'] == 200) {
        this.categoryList = response['data'];
        if(this.serviceId) this.getServiceById();
      } else {
        this._comm.errorMsg(response['message'])
      }
    })
  }

  addUpdateService(){
    this.submitted = true;
    this.servicesDetailForm.markAllAsTouched();
    if(this.servicesDetailForm.valid){
      const data = this.servicesDetailForm.value
      if(!this.serviceId){
      this._vendorApi.addServices(data).subscribe(response => {
        if (response['status'] == 200) {
          this._comm.successMsg(response['message'])
          this.dialogRef.close(true);
        } else {
          this._comm.errorMsg(response['message'])
        }
      })
    } else {
      data['id'] = this.serviceId;
      this._vendorApi.updateServices(data).subscribe(response => {
        if (response['status'] == 200) {
          this._comm.successMsg(response['message'])
          this.dialogRef.close(true);
        } else {
          this._comm.errorMsg(response['message'])
        }
      })
    }
   }
  }

  onImage(event, type) {
    if (event.target.files && event.target.files[0]) {
      if (this._comm.validateImageFile(event.target.files[0])) {
        let data = new FormData();
        data.append('file', event.target.files[0])
        data.append('type', type)
        this._api.uploadFile(data).subscribe((response: any) => {
          this.servicesDetailForm.controls['photo'].setValue(response.data.linkS3);
        })
      }
    }
  }

  closeModal(){
    this.dialogRef.close(false);
  }
}
