import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  dropSetting = {
    idField: "categoryId",
    textField: "category",
    singleSelection: false,
    text: "Select Categories",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: true,
    classes: "myclass custom-class",
    badgeShowLimit: 3,
  };
  singleDropSetting = {
    enableCheckAll: false,
    singleSelection: true,
    idField: "_id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: false
  };

  caregoryListConfig = {
    displayKey: "category",
    search: true,
    placeholder: 'Select Category',
    customComparator: () => { },
    limitTo: 0,
    height: "86",
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    selectAll: true,
    selectAllText: 'Select All',
    //searchOnKey: 'name',
    clearOnSelection: false,
    inputDirection: 'ltr'
  }

  serviceProvidersListConfig = {
    displayKey: "businessName",
    search: true,
    placeholder: 'Select Service Provider',
    customComparator: () => { },
    limitTo: 0,
    height: "86",
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    selectAll: true,
    selectAllText: 'Select All',
    clearOnSelection: false,
    inputDirection: 'ltr'
  }
  serviceListConfig = {
    displayKey: "serviceName",
    search: true,
    placeholder: 'Select Service',
    customComparator: () => { },
    limitTo: 0,
    height: "86",
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    selectAll: true,
    selectAllText: 'Select All',
    clearOnSelection: false,
    inputDirection: 'ltr'
  }
  professionalListConfig = {
    displayKey: "professionalName",
    search: false,
    placeholder: 'Select Professional',
    customComparator: () => { },
    limitTo: 0,
    height: "86",
    moreText: 'more',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Search',
    selectAll: true,
    selectAllText: 'Select All',
    clearOnSelection: false,
    inputDirection: 'ltr'
  }

  selectCouponConfig = {
    displayKey: "couponName",
    search: false,
    placeholder: 'Select Coupon',
    customComparator: () => { },
    limitTo: 0,
    height: "86",
    clearOnSelection: false,
    inputDirection: 'ltr'
  }

  discountTypeConfig = {
    displayKey: "discountType",
    search: false,
    placeholder: 'Select Discount Type',
    customComparator: () => { },
    limitTo: 0,
    height: "86",
    clearOnSelection: false,
    inputDirection: 'ltr'
  }

  bannerTypeConfig = {
    displayKey: "bannerType",
    search: false,
    placeholder: 'Select Banner Type',
    customComparator: () => { },
    limitTo: 0,
    height: "86",
    clearOnSelection: false,
    inputDirection: 'ltr'
  }

  currencyOptions = [
    { code: "INR", symbol: "₹" },
    { code: "KES", symbol: "K" },
    { code: "USD", symbol: "$" }
  ]
  salonTiming = [
    { "day": "monday", "status": false, "openTime": '', "closeTime": '' },
    { "day": "tuesday", "status": false, "openTime": '', "closeTime": '' },
    { "day": "wednesday", "status": false, "openTime": '', "closeTime": '' },
    { "day": "thursday", "status": false, "openTime": '', "closeTime": '' },
    { "day": "friday", "status": false, "openTime": '', "closeTime": '' },
    { "day": "saturday", "status": false, "openTime": '', "closeTime": '' },
    { "day": "sunday", "status": false, "openTime": '', "closeTime": '' },
  ]

  newBooking = new Subject<string>();

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrManager,
    private http: HttpClient) { }

  showSpinner() {
    this.spinner.show();
  }

  hideSpinner() {
    this.spinner.hide();
  }

  successMsg(message:string) {
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    this.toastr.successToastr(capitalize(message), '', {
      closeButton: false,
      toastTimeout: 3000,
      progressBar: true,
      progressAnimation: "decreasing",
      maxShown: 1
    });
  }

  errorMsg(message:string) {
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    this.toastr.errorToastr(capitalize(message), '', {
      closeButton: false,
      toastTimeout: 3000,
      progressBar: true,
      progressAnimation: "decreasing",
      maxShown: 1
    });
  }

  getCountryCode() {
    return this.http
      .get<Response>("assets/json/countryCode.json")
      .pipe(map(response => response));
  }

  validateImageFile(fileData) {
    if (fileData.type == 'image/jpeg' || fileData.type == 'image/png' || fileData.type == 'image/jpg' || fileData.type == 'image/svg' || fileData.type == 'image/webp' || fileData.type == 'image/gif') {
      return true;
    } else {
      this.errorMsg('Only Image file is accepted!')
      return false;
    }
  }
}
