import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../reset-password/must-match.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
 changePassword:FormGroup
  submitted: boolean;
  constructor( private fb:FormBuilder) { }

  ngOnInit() {

    this.changePassword= this.fb.group({
      oldPassword:['',Validators.required],
      password:['',Validators.required],
      confirmPassword:['',Validators.required]
    },{
      validator: MustMatch('password', 'confirmPassword')
    })

  }

  goToLogin(){
    this.submitted=true;
    if(this.submitted && this.changePassword.valid){
    }else{
      this.changePassword.markAllAsTouched();
    }
  }

}
